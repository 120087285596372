import { Link } from "gatsby"
import React from "react"
import * as styles from "./RelatedBlogs.module.scss"
import { Container, Row, Col } from "react-bootstrap"
const RelatedBlogs = ({ strapiData }) => {
  return (
    <React.Fragment>
      <div className={styles.quickReads}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <Row className={`${styles.cardWrapper} gap-30`}>
            {strapiData?.cards?.map((e, i) => (
              <Col xl={4} md={6} xs={12} key={i} className="gap-30 d-flex">
                <div className={styles.card}>
                  <Link
                    to={e?.buttons?.[0]?.url}
                  >
                    <img
                      className={styles.blogimg}
                      decoding="async"
                      loading="lazy"
                      src={e?.image1 && e?.image1[0]?.localFile?.publicURL}
                      alt={e?.image1 && e?.image1[0]?.alternativeText}
                    />
                  </Link>

                  <div
                    className={`${`d-flex justify-content-between`} ${
                      styles.topDetails
                    }`}
                  >
                    <Link
                      to={
                        i === 0
                          ? "/blog/category/web/"
                          : i === 1
                          ? "/blog/category/mobile-apps/"
                          : i === 2
                          ? "/blog/category/mobile-apps/"
                          : ""
                      }
                    >
                      {" "}
                      <p className={styles.category}>
                        {i === 0
                          ? "Web"
                          : i === 1
                          ? "Mobile Apps"
                          : i === 2
                          ? "Mobile Apps"
                          : ""}
                      </p>
                    </Link>

                    <div
                      className={`d-flex align-items-center ${styles.writer}`}
                    >
                      <div>
                        <Link
                          to={
                            i === 0
                              ? "/author/zeeshan-khan/"
                              : i === 1
                              ? "/author/zeeshan-khan/"
                              : i === 2
                              ? "/author/sadia-aziz/"
                              : ""
                          }
                        >
                          <p
                            className={styles.writerName}
                            dangerouslySetInnerHTML={{
                              __html: e?.subTitle,
                            }}
                          />
                        </Link>
                      </div>
                      <div>
                        <p className={styles.minRead}>
                          {e?.subTitle2}
                        </p>
                      </div>
                    </div>
                  </div>
                  <Link
                    to={
                      e?.buttons?.[0]?.url
                    }
                  >
                    <h3>{e?.title}</h3>
                  </Link>

                  <p className={styles.blogDesc} dangerouslySetInnerHTML={{__html: `${e?.description?.description}`}} />
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default RelatedBlogs;
