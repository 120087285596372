import { Script, graphql } from "gatsby"
import React from "react"
import EngagementModels from "../components/common/Engagement-Model/EngagementModels"
import Faqs from "../components/common/Faqs2"
import SEORevamp from "../components/common/SEO_Revamp"
import WhyChoose from "../components/consulting/Sets"
import Outsource from "../components/e-Commerce/Outsource"
import OurProjects from "../components/hire-remote-dev/Portfolio"
import OurProjectsMobile from "../components/hire-remote-dev/PortfolioMobile"
import Banner from "../components/real-estate/Banner"
import InnovateRealEstate from "../components/real-estate/InnovateRealEstate"
import OptimizeRealEstate from "../components/real-estate/OptimizeRealEstate"
import RealEstateTechSpecial from "../components/real-estate/RealEstateTechSpecial"
import RelatedBlogs from "../components/real-estate/RelatedBlogs"
import StayAhead from "../components/real-estate/StayAhead"
import TechStacks from "../components/real-estate/TechStacks"
import EndToEndRE from "../components/softwere-development/Process"
import ClientSuccessStories from "../components/web-application/ClientsWeb"
import MainLayout from "../layouts/MainLayout"

const RealEstate = ({ data }) => {
  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const heroSection = data?.strapiPage?.sections[0]
  const stayAhead = data?.strapiPage?.sections[1]
  const whyChoose = data?.strapiPage?.sections[2]
  const innovate = data?.strapiPage?.sections[3]
  const endToEnd = data?.strapiPage?.sections[4]
  const techSpecial = data?.strapiPage?.sections[5]
  const outsource = data?.strapiPage?.sections[6]
  const models = data?.strapiPage?.sections[7]
  const tools = data?.strapiPage?.sections[8]
  //   const optimization = data?.strapiPage?.sections[9]
  const projects = data?.strapiPage?.sections[10]
  const stories = data?.strapiPage?.sections[11]
  const stories1 = data?.stories?.sections[14]
  const blogs = data?.strapiPage?.sections[12]
  const faqs = data?.strapiPage?.sections[13]

  return (
    <MainLayout>
      <Banner strapiData={heroSection} />
      <StayAhead strapiData={stayAhead} />
      <WhyChoose strapiData={whyChoose} />
      <InnovateRealEstate strapiData={innovate} />
      <EndToEndRE strapiData={endToEnd} webSteps={true} />
      <RealEstateTechSpecial strapiData={techSpecial} />
      <Outsource strapiData={outsource} pageName="Real-Estate" />
      <div style={{ paddingTop: "100px" }}>
        <EngagementModels strapiData={models} />
      </div>
      <TechStacks strapiData={tools} />
      <OptimizeRealEstate
        heading={"Optimize Your Real Estate Operations With Us Today"}
        btnTitle={"Schedule A Meeting"}
        link="/contact-us"
      />
      {!isMobile && !isTablet ? (
        <OurProjects strapiData={projects} />
      ) : (
        <OurProjectsMobile strapiData={projects} />
      )}
      <ClientSuccessStories
        strapiData={{ ...stories, cards: stories1?.cards }}
        nocodeclient={true}
      />
      <RelatedBlogs strapiData={blogs} />
      <Faqs strapiData={faqs} pageName="Real-Estate" />
    </MainLayout>
  )
}

export const query = graphql`
  query realEstate {
    strapiPage(slug: { eq: "real-estate" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          subTitle
          subTitle2
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          url
          title
        }
      }
      buttons {
        url
        title
      }

      seo {
        metaTitle
        metaDescription
      }
    }
    stories: strapiPage(slug: { eq: "software-project-rescue-services" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
        }
      }
    }
  }
`

export default RealEstate

export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="post-hydrate"
      />
      <SEORevamp title={metaTitle} description={metaDescription} />
    </>
  )
}
