import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import * as styles from "./RealEstateTechSpecial.module.scss";



export default function RealEstateTechSpecial({ strapiData }) {
    return (
        <div className={styles.section}>
            <Container>
                <Row className='d-flex justify-content-center'>
                    <Col xs={12} sm={12} md={10} lg={8} xl={8}>
                        <h2
                            className={styles.heading}
                        >
                            {strapiData?.title}
                        </h2>
                        <div
                            className={styles.description}

                        >
                            {strapiData?.subTitle}
                        </div>
                    </Col>
                </Row>
                <Row>
                    {
                        strapiData?.cards?.map(el => <Col key={el?.title} xs={12} sm={6} md={6} lg={4}>
                            <div className={styles.cardContainer}>
                                <div className={styles.imgContainer}>

                                    <lottie-player
                                        autoplay
                                        loop
                                        src={el?.image1[0]?.localFile?.publicURL}
                                        style={{ height: "90px", width: "90px", margin: "0" }}
                                    />
                                </div>
                                <div className={styles.cardContentContainer}>
                                    <h4>{el?.title}</h4>
                                    <p>{el?.subTitle}</p>
                                </div>

                            </div>
                        </Col>)
                    }

                </Row>
            </Container>
        </div>
    )
}