// extracted by mini-css-extract-plugin
export var cardBg = "TechStacks-module--cardBg--6c778";
export var cards = "TechStacks-module--cards--6f102";
export var description = "TechStacks-module--description--d2e68";
export var heading = "TechStacks-module--heading--897f8";
export var infaHeading = "TechStacks-module--infaHeading--d4ea4";
export var nav = "TechStacks-module--nav--ceee9";
export var navItem = "TechStacks-module--nav-item--92c2e";
export var navbarBlock = "TechStacks-module--navbarBlock--7147e";
export var tabData = "TechStacks-module--tabData--6580d";
export var tech = "TechStacks-module--tech--8ba0a";
export var techIconssCard = "TechStacks-module--techIconssCard--2e3b1";
export var techInfrastructures = "TechStacks-module--techInfrastructures--fa706";
export var techStackImg = "TechStacks-module--techStackImg--26323";
export var techStackglobalIndus = "TechStacks-module--techStackglobalIndus--f64f9";
export var techStacksCardsContent = "TechStacks-module--techStacksCardsContent--3d5b5";
export var techStacksIcon = "TechStacks-module--techStacksIcon--74e4b";
export var technologyStacksIcon = "TechStacks-module--technologyStacksIcon--730db";