// extracted by mini-css-extract-plugin
export var bgColor = "InnovateRealEstate-module--bgColor--139a0";
export var bottomExperience = "InnovateRealEstate-module--bottomExperience--b6c17";
export var btn_white2_border_banner_web_App_maximize_btn = "InnovateRealEstate-module--btn_white2_border_banner_web_App_maximize_btn--6a252";
export var experienceData = "InnovateRealEstate-module--experienceData--fccb4";
export var experienceDetails = "InnovateRealEstate-module--experienceDetails--54aad";
export var experienceTitle = "InnovateRealEstate-module--experienceTitle--2cbbd";
export var headingProduct = "InnovateRealEstate-module--headingProduct--28cc1";
export var headingWeb = "InnovateRealEstate-module--headingWeb--ba836";
export var maximizeAngularDevelopers = "InnovateRealEstate-module--maximizeAngularDevelopers--c8a86";
export var maximizeBtnWeb = "InnovateRealEstate-module--maximizeBtnWeb--a43f9";
export var maximizeContent = "InnovateRealEstate-module--maximizeContent--6f8de";