import { Link } from "gatsby"
import React from "react"
import * as styles from "./InnovateRealEstate.module.scss"
import { Col, Container, Row } from "react-bootstrap"
const InnovateRealEstate = ({ strapiData, productmanager }) => {
  return (
    <div className={styles.bgColor}>
      <React.Fragment>
        <Container
          className={`${styles.maximizeAngularDevelopers} ${
            productmanager ? styles.productmaximize : ""
          }`}
        >
          <Row>
            <Col xs={12}>
              <div className={styles.maximizeContent}>
                <h2
                  className={`${styles.headingWeb} ${
                    productmanager ? styles.headingProduct : ""
                  }`}
                  dangerouslySetInnerHTML={{ __html: strapiData?.title }}
                />
                <div className={`${styles.maximizeBtnWeb}`}>
                  <Link
                    to="/contact-us/"
                    className={
                      styles.btn_white2_border_banner_web_App_maximize_btn
                    }
                  >
                    {strapiData?.buttons[0]?.title}
                  </Link>
                </div>
                <div
                  className={` justify-content-center d-flex ${styles.bottomExperience}`}
                >
                  {strapiData?.cards?.map((e, i) => (
                    <div className={styles.experienceDetails} key={i}>
                        <h3 className={styles.experienceTitle}>{e?.title}</h3>
                      <p
                        className={styles.experienceData}
                        dangerouslySetInnerHTML={{
                          __html: e?.subTitle,
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    </div>
  )
}

export default InnovateRealEstate;
