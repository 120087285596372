import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./TechStacks.module.scss"
import Row from "react-bootstrap/Row"

const data = [
  {
    name: "Node. JS",
    icon: "https://invozone-backend.s3.amazonaws.com/node_js_711090c234.svg",
  },
  {
    name: "React Native",
    icon: "https://invozone-backend.s3.amazonaws.com/react_native_24002add7f.svg",
  },
  {
    name: "Angular",
    icon: "https://invozone-backend.s3.amazonaws.com/angular_d6e64f74eb.svg",
  },
  {
    name: "Vue. JS",
    icon: "https://invozone-backend.s3.amazonaws.com/vue_js_a4e956b934.svg",
  },
  {
    name: "Php",
    icon: "https://invozone-backend.s3.amazonaws.com/php_94755e4217.svg",
  },
  {
    name: "Python",
    icon: "https://invozone-backend.s3.amazonaws.com/python_67da625707.svg",
  },
  {
    name: "Java",
    icon: "https://invozone-backend.s3.amazonaws.com/java_fc2c0dad43.svg",
  },
  {
    name: "Ruby",
    icon: "https://invozone-backend.s3.amazonaws.com/ruby_b54d2ec41a.svg",
  },
  {
    name: "Swift",
    icon: "https://invozone-backend.s3.amazonaws.com/swift_dfa74e0061.svg",
  },
  {
    name: "Objective C",
    icon: "https://invozone-backend.s3.amazonaws.com/objective_c_2ef8c2e339.svg",
  },
  {
    name: "Kotlin",
    icon: "https://invozone-backend.s3.amazonaws.com/kotlin_137acdf98e.svg",
  },
  {
    name: "Flutter",
    icon: "https://invozone-backend.s3.amazonaws.com/flutter_88e19ba78f.svg",
  },
]

const infaData = [
  {
    name: "AWS",
    icon: "https://invozone-backend.s3.amazonaws.com/aws_05b11ede3d.svg",
  },
  {
    name: "Google Cloud",
    icon: "https://invozone-backend.s3.amazonaws.com/google_cloud_70e11eef98.svg",
  },
  {
    name: "Microsoft Azure",
    icon: "https://invozone-backend.s3.amazonaws.com/microsoft_azure_ae54ba58e1.svg",
  },
  {
    name: "IBM Cloud",
    icon: "https://invozone-backend.s3.amazonaws.com/ibm_cloud_aa0b523f2b.svg",
  },
  {
    name: "Heroku",
    icon: "https://invozone-backend.s3.amazonaws.com/heroku_c83bd3b09d.svg",
  },
  {
    name: "Kubernetes",
    icon: "https://invozone-backend.s3.amazonaws.com/kubernets_1b894502fe.svg",
  },
  {
    name: "Docker",
    icon: "https://invozone-backend.s3.amazonaws.com/docker_5a9bc7f2eb.svg",
  },
]

const TechStacks = ({ strapiData }) => {
  return (
    <div className="TechStacksTabs">
      <div className={styles.techStackglobalIndus}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          ></h2>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <div className={styles.techStacksCardsContent}>
            <Row className={styles.techStacksIcon}>
              {data &&
                data?.map((e, i) => (
                  <div className={` ${styles.techIconssCard}`} key={i}>
                    <div className={styles.technologyStacksIcon}>
                      <div className={styles.techStackImg}>
                        <img
                          decoding="async"
                          loading="lazy"
                          src={e?.icon}
                          alt={e?.name}
                        />
                      </div>
                      <h3>{e?.name}</h3>
                    </div>
                  </div>
                ))}
            </Row>
          </div>
         
        </Container>
      </div>
    </div>
  )
}
export default TechStacks
