// extracted by mini-css-extract-plugin
export var blogDesc = "RelatedBlogs-module--blogDesc--2eaa8";
export var blogimg = "RelatedBlogs-module--blogimg--ae487";
export var card = "RelatedBlogs-module--card--dc673";
export var cardWrapper = "RelatedBlogs-module--cardWrapper--33d99";
export var category = "RelatedBlogs-module--category--53b52";
export var heading = "RelatedBlogs-module--heading--298c3";
export var minRead = "RelatedBlogs-module--minRead--13ff0";
export var quickReads = "RelatedBlogs-module--quickReads--ea5d2";
export var topDetails = "RelatedBlogs-module--topDetails--27f38";
export var writer = "RelatedBlogs-module--writer--c69f3";
export var writerName = "RelatedBlogs-module--writerName--957c8";