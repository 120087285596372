import React from 'react'
import { Link } from "gatsby";
import { Col, Container, Row } from "react-bootstrap";
import * as styles from "./OptimizeRealEstate.module.scss"


export default function OptimizeRealEstate({heading, btnTitle, link}) {
    return (
        <div className={styles.section}>
            <Container>
                <Row>
                    <Col className={styles.contentContainer}>
                        <h3 className={styles.heading}>{heading}</h3>
                        <div className={`${styles.btn}`}>
                            <Link
                                to={link}
                                className={
                                    styles.btn_white2_border_banner_web_App_maximize_btn
                                }
                            >
                                {btnTitle}
                            </Link>
                        </div>

                    </Col>
                </Row>
            </Container>
        </div>
    )
}